.front-page {
  position: absolute;
  top: var(--header-height);
  left: 0;
  width: 100%;
  height: calc(100% - var(--header-height));
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;

  h2 {
    font-size: 36px;
    margin: 1em 0;
    font-weight: bold;
    line-height: 120%;
  }

  footer {
    width: 100%;
    padding: 3em;
    background-color: white;
    color: black;
    text-align: center;
  }
}

.carousel {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
  padding: 2em;
  width: 100%;
  flex-grow: 1;

  .dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img, .text {
    height: 50vh;
    width: 35vw;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
  }

  img {
    border-radius: 24px;
    object-fit: cover;
  }

  .arrow {
    width: 23px;
    height: 52px;
    padding: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .arrow.left {
    background-image: url('../images/arrow-left.png');
  }

  .arrow.right {
    background-image: url('../images/arrow-right.png');
  }

  .dots {
    display: flex;
    gap: 16px;
  }

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #8f8f8f;
  }

  .dot.filled {
    background-color: #0c0c0c;
  }
}