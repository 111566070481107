
.macros {
  display: flex;
  flex-wrap: wrap;
  margin: 2em 0;

  a:hover {
    text-decoration: none;
  }

  .card {
    position: relative;
    padding: 2em;
    padding-bottom: 70px;
    border: 1px solid #ddd;
    border-radius: 0.5em;
    width: 30%;
    box-shadow: 5px 5px 5px #0001;
    margin-right: 1em;
    margin-bottom: 1em;

    .title {
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    .description {
      font-size: 0.9em;
    }

    .symbol {
      position: absolute;
      bottom: 0.5em;
      right: 0.5em;
      font-size: 40px;
      line-height: 1em;
    }
  }

  .card:hover {
    background: #f3f3f3;
    cursor: pointer;
  }
}

.macro-details {

  img {
    border: 1px solid #ddd;
  }
  .article {
    max-width: 700px;
  }
}

.macro-properties {
  position: fixed;
  top: 5em;
  right: 2em;
  width: 10em;
  padding: 1em;
  background: white;

  h2 {
    font-size: 1.5em;
  }

  div {
    margin-top: 1em;
  }
  a {
    color: #007aa3;
    word-break: break-all;
  }
  a.button {
    color: white;
  }
  ul {
    margin-bottom: 1em;
  }
}
