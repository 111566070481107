.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin: 0.5em;
  }
  hr {
    background: #ccc;
  }
}