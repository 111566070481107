.article {

  padding: 3em 5em;
  max-width: 800px;
  font-family: CiscoSansLight;
  font-size: 16px;
  color: #292929;

  h1 {
    line-height: 1.2em;
  }

  h2 {
    font-size: 18px;
    margin-top: 3em;
    padding-bottom: 1em;
    border-bottom: 1px solid #ddd;
  }

  h3 {
    font-size: 18px;
    margin-top: 2em;
  }

  .intro {
    font-size: 20px;
    color: rgb(84, 84, 84);
  }

  p {
    margin: 1em 0;
    line-height: 1.7em;
  }

  em {
    font-style: italic;
  }

  // todo use same for code example for xapi node details
  pre {
    margin: 1em 0;
    padding: 1em;
    background: #333;
    color: #ccc;
    border-radius: 0.3em;
    font-family: monospace;
    border: 0;
    line-height: 1.5em;
    font-size: inherit;
    white-space: break-spaces;
  }

  ul li {
    list-style-type: disc;
    margin: 1em;
  }

  p > code {
    padding: 0.2em 0.5em;
    background-color: #ededed;
    font-family: monospace;
  }

  strong {
    font-family: CiscoSansRegular;
  }

  a {
    color: #007aa3;
    text-decoration: none;
    font-family: CiscoSansRegular;
  }

  a:hover, a:active {
    color: #007aa3;
  }

  td, th {
    border: 1px solid #eee;
    padding: 0.5em;
    vertical-align: middle;
  }
  table {
    margin: 2em 0;
  }

  img {
    margin: 2em 0;
    max-width: 100%;
  }

  img.border {
    border: 1px solid #ccc;
  }

  img.logo {
    height: auto;
    width: 6em;
    border: 0;
    margin: 0;
  }

  hr {
    margin: 2em 0;
    height: 2px;
    border: 0;
    background-color: #ccc;
  }
}

main.print {
  position: relative;
  left: 0;
  top: 0;
  padding: 2em 0;
  margin: 0;
  height: auto;
  width: auto;
  overflow-y: unset;
}

.print .article {
  max-width: 1000px;
}

.print-link {
  position: absolute;
  top: calc(var(--header-height) + 1.5em);
  font-size: 16px;
  right: 1.5em;
}

/* using markdown code for special purpose blocks */
pre.language-alert {
  background-color: #ffe8e3 !important;

  code {
    color: red !important;
  }
}
pre.language-info {
  background-color: #c9f4ff !important;
}
pre.language-note {
  background-color: #ffecc2 !important;
}

img.float {
  float: right;
  margin: 0;
  width: 200px;
  margin-left: 1em;
  border: 1px solid black;
}

.horisontal-list {
  display: flex;
  gap: 1em;
}

.horisontal-list > * {
  width: 0;
  flex-grow: 1;
}

/** hide quicklist unless screen is almost fullscreen */
@media (max-width: 1300px) {
  .quick-list {
    display: none;
  }
}

.articles {
  display: flex;
  flex-wrap: wrap;
  margin: 2em 0;

  .card {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 0.5em;
    width: 27%;
    box-shadow: 5px 5px 5px #0001;
    margin-right: 2em;
    margin-bottom: 2em;
    padding: 15px;
  }

  .card .title {
    font-weight: bold;
    margin-bottom: 0.5em;
    color: #007aa3;
  }

  .card .description {
    padding-top: 0;
    font-size: 0.9em;
  }

  .card:hover {
    text-decoration: none;
    background-color: #f1f1f1;
  }

  .card .symbol {
    width: 100%;
    font-size: 70px;
    height: 112px;
    line-height: 112px;
    text-align: center;
  }

  .card .image {
    height: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0.5em;
    margin-bottom: 15px;
  }

}