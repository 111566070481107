.image-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: calc(100% - var(--header-height));

  button:active {
    background-color: #ccc;
  }

  button:hover {
    background-color: #ddd;
  }

  button {
    margin: 1em;
  }

  .search {
    width: 25em;
  }

  .wallpaper, .virtual-bg {
    width: 100%;
  }

  .virtual-bg {
    display: flex;
    background-color: #fffc;
    border-radius: 1em;
    padding: 0 1em;
    height: 2em;

    button, select {
      margin: 0;
      height: 100%;
      border-radius: 0;
      padding: 0;
      padding-right: 0.5em;
      background-color: transparent;
    }
  }
}

.gallery {
  max-width: 1024px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;

  button {
    background-color: #fffc;
  }
}

.gallery .image-thumbnail {
  width: 300px;
  height: 200px;
  margin: 1em;
  position: relative;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #ddd;

  .fade {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em 4em;
    justify-content: flex-end;
    align-items: center;
  }
}
