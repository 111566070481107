
.node-details {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: #191919;
  color: white;
  overflow-y: auto;
  padding: 2em;
  padding-top: 2em;
  font-size: 14px;

  .name {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
  }

  .api-details {
    overflow-y: auto;
    margin-bottom: 0;
    color: white;
  }

  .params {
    background: #333;
    border-radius: 0.3em;
    padding: 0.3em;
  }

  .param {
    padding: 1em;
    font-size: 12px;
  }

  .required {
    color: #d97f00;
  }

  .param .name {
    font-family: Courier, monospace;
  }

  .param .description {
    margin-left: 1em;
  }

  .props {
    margin: 1em 0;
    width: 100%;
  }

  .props td {
    padding: 0.3em;
  }

  .props td:first-child {
    width: 120px;
    color: #ccc;
  }

  .props td:last-child {
    color: white;
  }

  .note {
    background-color: #fef3d4;
    border-radius: 0.3em;
    padding: 0.5em;
    color: #666;
    margin: 1em 0;
  }

  .api-code {
    padding: 1em 0;
    margin-bottom: 0;
    color: white;
  }

  .code {
    position: relative;
    margin: 1em 0;
    text-align: right;
  }

  .code pre {
    display: block;
    text-align: left;
    position: relative;
    white-space: pre-line;
    overflow-wrap: anywhere;
    padding: 1em;
    background: #333;
    color: white;
    border-radius: 0.3em;
    font-family: monospace;
    width: 100%;
    line-height: 1.5em;
  }

  .code .copy {
    background: transparent;
    border: 0;
    color: #999;
    border: 1px solid #999;
    border-radius: 0.3em;
    padding: 0.3em;
  }

  .code .copy:hover {
    background: #666;
  }

  .code .copy:active {
    background: #777;
  }

  .switcher {
    margin: 1.5em;
    display: inline-flex;
    cursor: pointer;
    border-radius: 0.3em;
    border: 1px solid #00a0d1;

    .switch {
      padding: 0.3em 1em;
    }
    .switch:hover {
      background-color: #007aa3;
    }
    .selected {
      background: #00a0d1;
      color: white;
    }

  }
}
