.form {
  font-size: 13px;
  max-height: 50vh;
  width: 30vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  a {
    text-decoration: underline;
  }

  .name {
    font-weight: bold;
    margin-top: 1em;
  }

  .info {
    font-size: 0.9em;
    color: #333;
  }

  input, textarea, select {
    width: 100%;
    margin: 0.3em 0;
    padding: 0.5em 1em;
    font-size: inherit;
  }

  input[type=checkbox] {
    width: auto;
  }

  .invalid :is(input, textarea, select) {
    border: 1px solid red;
    background: #f003;
  }
}

.form.connect {
  width: 30vw;
  input {
    margin: 0.7em 0;
  }
}