.section {
  margin-top: 1em;
  position: relative;
}
.section::after {
  content: '^';
  position: absolute;
  right: 0;
  transform: scale(-1, -1);
  transition: transform 0.2s;
}

.section.open::after {
  transform: scale(1, 1);
}

menu {
  border: 1px solid transparent; /* fixes the collapsing border from h1 */
  height: 100%;
  border-right: 1px solid #ccc;
  padding-left: 2em;
  font-family: CiscoSansRegular;
  overflow-y: auto;
}

menu a {
  display: block;
  font-size: 16px;
  margin: 1.2em 0;
  margin-right: 2em;
  color: black;
  text-decoration: none;
}

menu a.selected {
  color: #007aa3;
}

menu h1 {
  margin-top: 1em;
  font-size: 12px;
  color: rgb(84, 84, 84);
  text-transform: uppercase;
  padding: 1em 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.sub-menu {
  margin-left: 1em;
  margin-bottom: 1em;
}

.hidden {
  display: none;
}
