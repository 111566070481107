

:root {
  --header-height: 80px;
  --menu-width: 15em;
  --selected-color: #00a0d1;
}

@font-face {
  font-family: 'CiscoSansThin';
  src: url('../fonts/CiscoSansThin.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CiscoSansLight';
  src: url('../fonts/CiscoSansTTLight.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CiscoSansRegular';
  src: url('../fonts/CiscoSansTTRegular.woff');
  font-weight: normal;
  font-style: normal;
}

body, html, #app {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: CiscoSansThin, Arial;
  height: 100%;
  line-height: 1.5em;
}

/* main skeleton */
.screen {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.screen > header {
  height: var(--header-height);
}

.screen > header {
  position: relative;
  width: 100%;
  background: #292929;
  color: white;
  display: flex;
  align-items: center;
  padding: 0;
  border-bottom: 1px solid black;
  padding-right: 6em;
  flex-shrink: 0;

  .links {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-grow: 1;
  }

  a {
    font-family: CiscoSansRegular;
    text-decoration: none;
    margin: 0 1em;
  }

  a:hover {
    color: #007aa3;
    text-decoration: none;
  }

  .selected {
    color: var(--selected-color);
  }

  button {
    margin: 0 0.5em;
    color: white;
  }
}

.master-detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.master {
  width: var(--menu-width);
  height: 100%;
}

.detail {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.main {
  position: relative;
  padding: 2em;
  overflow-y: auto;
  color: #292929;
  overflow-y: auto;
  height: 100%;
  border: 1px solid transparent; /* avoid margin collapsing from children */
}

main.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - var(--header-height));
  overflow-y: auto;
  padding: 2em;
}

a {
  color: inherit;
  text-decoration: none;
}

.api-list {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .selected {
    font-family: CiscoSansRegular;
  }

  hr {
    background-color: #ddd;
    margin: 1.5em 0;
  }

  .selected a {
    font-weight: bold;
  }
}

button, a, select {
  cursor: pointer;
  font-size: inherit;
}

h2 {
}

hr {
  border: none;
  height: 1px;
  background-color: #444;
}

a:hover {
  text-decoration: underline;
}

.node-path a {
  font-family: CiscoSansRegular;
  text-decoration: none;
  color: rgba(41, 41, 41);
}

.node-path a:hover {
  text-decoration: underline;
}

main img {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 1em 0;
}

main .info {
  margin: 1em 0;
}

h1 {
  font-size: 36px;
  margin-bottom: 1em;
  color: rgb(41, 41, 41);
  font-family: CiscoSansRegular;
}

h2, h3 {
  margin: 1em 0;
  font-size: 16px;
  font-family: CiscoSansRegular;
}

.icon {
  font-family: momentum-ui-icons;
}
.icon:active {
  font-weight: bold;
}

.label {
  display: inline-block;
  background: #eee;
  vertical-align: bottom;
  padding: 0.2em 0.5em;
  color: white;
  border-radius: 0.5em;
  margin: 0 2em;
  margin-left: 0;
  width: 7em;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-family: CiscoSansRegular;
}

.label.short {
  width: 4em;
}

.Status {
  background-color: #00ab50;
}

.Command {
  background-color: #b02792;
}

.Configuration {
  background-color: #00a0d1;
}

.Event {
  background-color: #d97f00;
}

.node-path {
  margin: 1em 0.5em;
  display: flex;
  align-items: flex-start;
}

.api-list section {
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  border-top: 1px solid #eee;
  padding-top: 1em;
}

th {
  background: #eee;
  text-align: left;
}

table.links {
  td:first-child {
    min-width: 200px;
  }
  td, th {
    padding: 1em;
  }
}

.page {
  max-width: 900px;
  padding: 3em;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#copy-paste {
  opacity: 0;
  height: 0;
  width: 0;
}

.warning {
  color: #856404;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 0.6em;
  border-radius: 0.3em;
  font-weight: bold;
}

header .logo {
  width: 12em;
  height: 100%;
  background-image: url('../images/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

button, a.button {
  border: 0;
  padding: 0.5em 1em;
  border-radius: 5em;
  color: black;
  background: #eee;
}

a.button {
  display: inline-block;
  margin: 1em 0;
}

button.primary, a.button {
  background: #02a1d0;
  color: white;
}

button.primary:hover, a.button:hover {
  background: #007aa3;
  color: white;
  text-decoration: none;
}

button.danger {
  background: #d01f40;
  color: white;
}

button.danger:hover {
  background: #b41b37;
}

button.secondary {
  background: transparent;
}

button:hover:disabled {
  background: #02a1d0;
  cursor: unset;
}

input, textarea, select {
  margin: 1em 0;
  background: #eee;
  border: 0;
  border-radius: 1em;
  padding: 1em;
  outline: 0;
}

.dialog {
  background: white;
  min-width: 200px;
  border-radius: 1em;
  padding: 2em;
}

button:disabled, input:disabled, textarea:disabled {
  opacity: 0.5;
}

li {
  margin: 0 1em;
}

.quick-list {
  position: fixed;
  top: 10rem;
  right: 1.5rem;
  max-width: 20em;
  max-height: 50vh;
  line-height: 2em;
  overflow-y: auto;
  padding: 2em;
  background-color: white;
  font-family: CiscoSansRegular;
  font-size: 16px;


  a {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0.5em;
  }

  a:hover {
    background-color: #0ac2;
    text-decoration: none;
  }
}

.feedback {
  textarea {
    width: 40em;
    height: 10em;
  }
  button {
    margin-right: 1em;
  }
}

.error {
  color: red;
}

.notification {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 2em;
  line-height: 2em;
  text-align: center;
  width: 100%;
  background-color: #0acc;
  color: white;
}

.notification.error {
  background: #d23451;
  opacity: 0.9;
}

.box {
  margin: 1em 0;
  border: 1px solid #ccc;
  border-radius: 0.3em;
  padding: 2em;
  padding-left: 50%;
  background-position: 20% center;
  background-size: auto 40%;
  background-repeat: no-repeat;
}

.box.xapi-teams {
  background-image: url('../images/xapispace.png');
}

.box.cisco-forum {
  background-image: url('../images/forum.png');
}

.rotate-tip {
  display: none;
}

.xapi-basics {
  td {
    border: 0;
    vertical-align: text-top;
    padding-bottom: 1.5em;
  }
}

.diff-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  margin-top: 1em;
  margin: 1em 0;
}

.xapi-diff .search-bar {
  box-shadow: none;
  border: 0;
  font-size: 1em;
}

input[type=radio] {
  cursor: pointer;
  margin-left: 1em;
  margin-right: 0.5em;
}