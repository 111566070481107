.admin .form {
  display: flex;
  flex-direction: row;
  width: unset;
  gap: 1em;
  max-height: unset;
}

.schema-table {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 1em;
  align-items: center;
  max-height: 60vh;
  overflow-y: auto;
}

.schema-table .schema {
  font-weight: bold;
  cursor: pointer;
}

.admin .widget {
  background-color: #ddd;
  padding: 1em;
  border-radius: 1em;
}

.admin input[type=text] {
  width: 8em;
}

.admin input[type=text]:focus {
  border: 1px solid rgb(39, 107, 152);
}