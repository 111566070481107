.search-bar {
  padding: 0.5em;
  background: white;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  box-shadow: 0px 1px 7px #0002;
  overflow-x: auto;
  overflow-y: hidden;

  select, input, button {
    font-size: inherit;
    background: #eee;
    border: 0;
    padding: 0.3em;
    border-radius: 0.3em;
    margin: 0.5em;
    color: inherit;
    outline: 0;
  }

  button.primary {
    height: 2em;
    padding: 0.3em 1em;
  }

  .active {
    background: #4cb6d6;
    border: 1px solid #02a1d0;
    color: white;
  }

  .type-buttons {
    background: #eee;
    margin: 0.5em;
    border-radius: 0.3em;
    flex-shrink: 0;
  }

  .type-buttons button {
    margin: 0;
    padding: 0.5em 0.8em;
    border-radius: 0;
  }

  .type-buttons button:first-child {
    border-radius: 0.3em 0 0 0.3em;
  }

  .type-buttons button:last-child {
    border-radius: 0 0.3em 0.3em 0;
  }

  .checkbox {
    background-color: #eee;
    padding-right: 10px;
    border-radius: 0.3em;
    font-size: inherit;
    font-family: arial;
    flex-shrink: 0;
  }

  label {
    cursor: pointer;
  }
}
